import {normalize} from 'polished';
import {createGlobalStyle} from 'styled-components';
import AktivGroteskMedium from './fonts/AktivGrotesk-Medium.woff';
import AktivGrotesk from './fonts/AktivGrotesk-Regular.woff';

export const GlobalStyle = createGlobalStyle`
  ${normalize()}

  @font-face {
    font-family: 'AktivGrotesk';
    src: url(${AktivGrotesk}) format('woff');
  }

  @font-face {
    font-family: 'Aktiv Grotesk';
    src: url(${AktivGroteskMedium}) format('woff');
    font-weight: 500;
    font-style: normal;
  }

  * {
    box-sizing: border-box;
    outline: none;
  }
  
  html, body, #root {
    height: 100%;
    background: #fff;
    /* font-family: 'AktivGrotesk', -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; */
    font-family: AktivGrotesk, serif;
  }
  
  body {
    font-size: 16px;
    line-height: 1.5;
    color: #657496;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
  }

  .map-container {
    height: 100%;
    width: 100%;
  }
  .map-ref {
    height: 100%;
  }
  .gm-style .gm-style-iw {
    background: none;
    border-radius: 0;

    & > button {
      display: none !important;
    }
  }
  .gm-style .gm-style-iw-t::after {
    background: #657496;
  }
  .gm-style .gm-style-iw-d {
    overflow: hidden !important;
  }
  .gm-style .gm-style-iw-c {
    box-shadow: none;
    padding: 0 !important;
  }

  .iw-condutor-container {
    background-color: #657496;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px;

    & .iw-condutor-avatar {
      display: flex;
      margin-right: 15px;
    }

    & .iw-condutor-info {
      & label {
        color: #afb7c9;
        font-size: 12px;
        margin-bottom: 3px;
        display: block;
      }

      & p {
        margin: 0 0 8px;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }

  .slick-initialized .slick-slide {
    opacity: 0;
  }
  .slick-initialized .slick-active {
    opacity: 1;
  }
`;
