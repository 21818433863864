import {create as createApiSauce} from 'apisauce';
import config from '../config/config';

const RedefinirSenhaApi = createApiSauce({
  baseURL: `${config.AUTH_BASE_URL}/public/v1/usuario/redefinir-senha`,
  headers: {
    'Content-Type': 'application/json',
  },
});

const salvarNovaSenha = async (token, senha) => {
  try {
    const response = await RedefinirSenhaApi.put(`${token}`, {
      senha,
    });

    if (response.ok) {
      return 'Sua senha foi redefinida com sucesso.';
    }

    if (typeof response.data.mensagem === 'string') {
        throw new Error(response.data.mensagem);
    }
    if (typeof response.data.message === 'string') {
        throw new Error(response.data.message);
    }

    throw new Error('Ops! Ocorreu um erro.');
  } catch (error) {
    throw error;
  }
};

const RedefinirSenhaService = {
    salvarNovaSenha,
};

export default RedefinirSenhaService;