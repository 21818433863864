import React, {useState} from 'react';
import {withRouter} from 'react-router-dom';
import queryString from 'query-string';
import * as Yup from 'yup';
import styled from 'styled-components';
import {TextField} from 'formik-material-ui';
import Header from './components/header';
import Footer from './components/footer';
import {Formik, Field} from 'formik';
import RedefinirSenhaService from '../../services/RedefinirSenhaService';

const RedefinirSenha = ({location}) => {
  const [loading, setLoading] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState(null);
  const [messageError, setMessageError] = useState(null);

  const handleSubmit = async ({novaSenha}, {setSubmitting}) => {
    if (loading) {
      return;
    }

    const {token} = queryString.parse(location.search);

    if (!token) {
      setMessageError('Link de recuperação inválido.');
      return;
    }

    await salvarNovaSenha(token, novaSenha);

    setSubmitting(false);
  };

  const salvarNovaSenha = async (token, novaSenha) => {
    try {
      setLoading(true);
      const message = await RedefinirSenhaService.salvarNovaSenha(token, novaSenha);
      setMessageSuccess(message);
      setMessageError(null);
    } catch (error) {
      setMessageSuccess(null);
      setMessageError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Header />
      <Content>
        <Formik
          validationSchema={RedefinirSenhaScheme}
          initialValues={{novaSenha: '', confirmarNovaSenha: ''}}
          onSubmit={handleSubmit}
          render={({handleSubmit}) => (
            <Form onSubmit={handleSubmit}>
              <PageTitle>
                Redefina sua senha
              </PageTitle>

              <FormWrapper>
                {messageSuccess && (
                  <AlertSuccess>
                    <AlertText>{messageSuccess}</AlertText>
                  </AlertSuccess>
                )}
                {messageError && (
                  <AlertError>
                    <AlertTextError>{messageError}</AlertTextError>
                  </AlertError>
                )}

                <Field
                  component={StyledTextField}
                  label="Nova senha"
                  margin="normal"
                  name="novaSenha"
                  type="password"
                />

                <Field
                  component={StyledTextField}
                  label="Confirmar nova senha"
                  margin="normal"
                  name="confirmarNovaSenha"
                  type="password"
                />

                <ButtonPrimary type="submit" disabled={loading}>
                  {loading ? 'Aguarde...' : 'Redefinir senha'}
                </ButtonPrimary>
              </FormWrapper>
            </Form>
          )}
        />
      </Content>
      <Footer />
    </Container>
  );
};

const RedefinirSenhaScheme = Yup.object().shape({
  novaSenha: Yup.string().required('Campo obrigatório'),
  confirmarNovaSenha: Yup.string()
    .required('Campo obrigatório')
    .oneOf(
      [Yup.ref('novaSenha'), null],
      'Nova senha não confere com confirmação de senha.'
    ),
});

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  padding-left: 130px;
  width: 545px;
  height: 100%;
  align-items: center;

  @media (max-width: 1024px) {
    width: 100%;
    padding-left: 0;
  }
`;

const Form = styled.form`
  width: 80%;

  @media (max-width: 1024px) {
    width: 100%;
    padding: 30px;
  }
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const PageTitle = styled.h1`
  font-weight: bold;
  font-size: 34px;
  color: #262626;
  margin-top: 0;

  @media (max-width: 1024px) {
    font-size: 24px;
  }
`;

const StyledTextField = styled(TextField)``;

const ButtonPrimary = styled.button`
  height: 50px;
  line-height: 50px;
  border-radius: 2px;
  background-color: #0083e6;
  border: 0;
  color: #fff;
  cursor: pointer;
  margin-top: 30px;
  margin-bottom: 30px;
`;

const AlertSuccess = styled.div`
  background-color: #1affa2;
  padding: 25px;
`;
const AlertError = styled.div`
  background-color: #ff5252;
  padding: 25px;
`;
const AlertText = styled.p`
  font-size: 14px;
  margin: 0;
  color: #262626;
`;
const AlertTextError = styled(AlertText)`
  color: #fff;
`;

export default withRouter(RedefinirSenha);
