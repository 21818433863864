import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 90px;
  border-bottom: 1px solid #d5dce3;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  display: flex;
  padding-right: 100px;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const HeaderTitleContainer = styled.div`
  padding-left: 84px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const HeaderTitle = styled.h1`
  font-weight: bold;
  font-size: 24px;
  color: #0091ff;
  margin-bottom: 0;
  margin-top: 0;
  margin-left: 32px;
`;

const Header = () => (
  <Container>
    <HeaderTitleContainer>
      <HeaderTitle></HeaderTitle>
    </HeaderTitleContainer>
  </Container>
);

export default Header;
