import React from 'react';
import { RouterAuthComponent } from './router/router-auth';
import {GlobalStyle} from './theme';

function App() {
  return (
    <>
    <GlobalStyle />
    <RouterAuthComponent />
    </>
  );
}

export default App;
