import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 90px;
  display: flex;
  padding-left: 130px;
  padding-right: 100px;
`;

const FaleComSuporteLink = styled.a`
  color: #595959;
  font-size: 14px;
  cursor: pointer;
`;

const Footer = () => (
  <Container>
    <FaleComSuporteLink></FaleComSuporteLink>
  </Container>
);

export default Footer;
