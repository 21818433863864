import React from 'react';
import {
  HashRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import styled from 'styled-components';
import RedefinirSenhaView from '../views/auth/redefinir-senha';
import BannerBgImg from '../assets/images/banner-bg-man@3x.png';
import KmMoneyLogoImg from '../assets/images/km-money@3x.png';

const NotFound = () => <div />;

export const RouterAuthComponent = () => (
  <FullWrapper>
    <BannerContainer>
      <KmMoneyLogo src={KmMoneyLogoImg} alt="kmmoney" />
    </BannerContainer>
    <Container>
      <Router style={{ height: '100%' }}>
        <Switch>
          <Route path="/redefinir-senha">
            <RedefinirSenhaView />
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </Router>
    </Container>
  </FullWrapper>
);

const FullWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  background: #fff;
  min-height: 750px;

  @media (max-width: 1024px) {
    min-height: none;
    flex-direction: column;
  }
`;

const BannerContainer = styled.div`
  width: 50%;
  height: 100%;
  background: url(${BannerBgImg}) top center no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: 1024px) {
    width: 100%;
    height: 150px;
    padding: 30px;
  }
`;

const Container = styled.div`
  width: 50%;
  height: 100%;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const KmMoneyLogo = styled.img`
  margin-top: 13%;

  @media (max-width: 1024px) {
    margin-top: 0;
  }
`;
